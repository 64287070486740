import React, { useCallback, useEffect, useState } from "react";
import { OTSession, OTSubscriber } from "opentok-react";
import Lottie from "react-lottie";
import { Box, Typography } from "@material-ui/core";
import { Warning, AvTimer } from "@material-ui/icons";
import { useCallContext } from "../../reducers/Call/CallProvider";
import { CallActionTypes } from "../../reducers/Call/CallActionsTypes";
import Logo from "../../Logo";
import Loader from "../../assets/lottie/loader.json";
import { fullHeight } from "../../utils/constants";
import CamOnIcon from "../../assets/camOnIcon.svg";
import CamOffIcon from "../../assets/camOffIcon.svg";
import AudioOnIcon from "../../assets/sound-off-icon.svg";
import AudioOffIcon from "../../assets/volumeIconOff.svg";
import DeclineBtn from "../../assets/declineButton.svg";
import NoUserIcon from "../../assets/no-user-icon.svg";

import { CallPreview } from "./CallPreview";
import { useIsOpen } from "src/hooks";
import CallChat from "./CallChat";
import { CallTitleInfo } from "./CallTitleInfo";

import AsideTools from "./AsideTools";
import SwitchOperator from "src/components/SwitchOperator/SwitchOperator";
import CountdownTimer from "src/utils/CountdownTimer";
import { CallService } from "src/services/CallService";
import { checkOpentokRole } from "src/utils/checkOpentokRole";
import toast from "react-hot-toast";

export const CallPage = () => {
  const [messages, setMessages] = useState([]);
  const { isOpen, toggle, close, open } = useIsOpen();
  const {
    isOpen: isOpenSwitchModal,
    // toggle: toggleSwitchModal,
    close: closeSwitchModal,
    open: openSwitchModal,
  } = useIsOpen();
  const initialTimerValue = 10 * 60;
  // const newMessages = useRef(0);
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const [isCountdownStart, setIsCountdownStart] = useState(false);
  const [countdownTime, setCountdownTime] = useState(initialTimerValue);

  const {
    callState,
    dispatchCall,
    publisher,
    reserveCall,
    sessionRef,
    setChatMessages,
    setRightTabId,
    canStartCall,
    setCanStartCall,
    streamAvailable,
    setStreamAvailable,
    subscribersToRender,
    setSubscribersToRender,
    isOffSpeaker,
    setIsOffSpeaker,
    handUpCount,
    setHandUpCount,
    handleAccept,
    endCall,
    flag,
    socket,
    // subscribers,
  } = useCallContext();

  const { selectedCall } = callState;

  const isGroupCall = selectedCall.reason === "group";

  const uniqueIds = new Set();

  const filteredSubscribers = subscribersToRender.filter((item) => {
    const isUnique = !uniqueIds.has(item.stream.id);
    uniqueIds.add(item.stream.id);
    return isUnique;
  });

  const handleDeclineCall = useCallback(() => {
    if (selectedCall.type === "CONNECT_PRO_WEB_GROUP_CALL") {
      CallService.endGroupWebProCall(selectedCall.session_id);
    }
    dispatchCall({
      type: CallActionTypes.END_CALL,
    });
    setCountdownTime(initialTimerValue);

    sessionRef?.current?.sessionHelper.session.signal({ type: "endCall" });
  }, [dispatchCall, initialTimerValue, sessionRef, selectedCall]);

  const sessionEventHandlers = {
    streamCreated: (event) => {
      setCountdownTime(initialTimerValue);
      setIsCountdownStart(false);
      // console.log("Stream Created", event);
      // console.log("Stream Created", event.target.connections.length());
      listenMessages();

      const { stream } = event;
      // connections+=1;
      // if (event.target.currentState === "disconnected") {
      //   console.log("handle dissconnect");
      // }
      const newUser = {
        stream,
        isCameraOn: stream.hasVideo,
        isAudioOn: stream.hasAudio,
        name: stream.name,
        isShowEndBtn: (isGroupCall && stream.name.includes("amsaanpro")) || !isGroupCall,
        isAmsaanPro: stream.name.includes("amsaanpro"),
        isSelectedUser: !isGroupCall || (isGroupCall && stream.name.includes("amsaanpro")),
      };
      //  dispatchCall({
      //    type: CallActionTypes.END_CALL,
      //  });
      setSubscribersToRender((prevState) => [...prevState, newUser]);

      setStreamAvailable(true);
      flag.current = true;
    },
    streamDestroyed: (event) => {
      console.log("Stream Destroyed", event);
      const userName = event.stream.name;
      const isGroupCall = selectedCall.reason === "group";

      setSubscribersToRender((prevState) =>
        prevState.filter((el) => el.stream.id !== event.stream.id)
      );

      if (userName.includes("amsaanpro") || !isGroupCall) handleDeclineCall();
    },
    onStreamsUpdated: (event) => {
      console.log("onStreamsUpdated", event);
    },
    streamPropertyChanged: (event) => {
      console.log("event streamPropertyChanged", event);
      const { stream } = event;

      const newUser = {
        stream,
        isCameraOn: stream.hasVideo,
        // isAudioOn: stream.hasAudio,
        name: stream.name,
        isShowEndBtn: (isGroupCall && stream.name.includes("amsaanpro")) || !isGroupCall,
        isAmsaanPro: stream.name.includes("amsaanpro"),
        isSelectedUser: !isGroupCall || (isGroupCall && stream.name.includes("amsaanpro")),
      };
      setSubscribersToRender((prevState) =>
        prevState.map((el) => (el.stream.id === event.stream.id ? { ...el, ...newUser } : el))
      );
    },
    sessionDisconnected: (event) => {
      console.log("sessionDisconnected", event);
    },
    connectionDestroyed: (event) => {
      console.log("connectionDestroyed", event);
    },
    signal: (event) => {
      console.log("signal event ", event);
      if (event.type === "signal:endCall") {
        handleDeclineCall();
      }
    },
  };
  // console.log("subscribersToRender", subscribersToRender);
  // console.log("OTSession, OTSubscriber", OTSession, OTSubscriber);

  useEffect(() => {
    if (!isCountdownStart) return;

    if (countdownTime === 0) {
      if (callState.selectedCall.call.reason === "callback") {
        toast.error("На жаль юзер не прийняв дзвінок");
      }
      handleDeclineCall();
      return;
    }

    const intervalId = setInterval(() => {
      setCountdownTime((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdownTime, dispatchCall, isCountdownStart, handleDeclineCall, callState.selectedCall]);

  useEffect(() => {
    if (!Boolean(callState.selectedCall.session_id?.length)) {
      endCall();
      setMessages([]);
      close();
      setIsCountdownStart(false);
    }

    if (Boolean(callState.selectedCall.session_id?.length) && !streamAvailable) {
      setCountdownTime(60);
      setIsCountdownStart(true);
    }
  }, [callState.selectedCall, streamAvailable]);

  useEffect(() => {
    if (isOpen) return;
    if (messages.length === 1) {
      open();
    }
    if (messages.length) {
      setNewMessagesCount((prev) => (prev += 1));
    }
  }, [messages.length]);

  const hasRole = useCallback(checkOpentokRole, []);
  function listenMessages() {
    socket.current.on(
      "call-messages." + selectedCall.session_id + ":message.received",
      (message) => {
        // console.log(message);
        setMessages((prev) => [...prev, message]);
      }
    );
  }

  function handleToggleChatVisible() {
    toggle();
    setNewMessagesCount(0);
  }

  return (
    <Box
      width="100%"
      style={{
        verticalAlign: "center",
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        display: "flex",
      }}
    >
      {!!selectedCall.session_id.length && !canStartCall ? (
        <CallPreview
          setCanStartCall={setCanStartCall}
          reserveCall={reserveCall}
          startedAt={new Date(callState.selectedCall.started_at)}
          handleAccept={handleAccept}
        />
      ) : !!selectedCall.session_id.length ? (
        <Box width="100%" height={fullHeight} style={{ width: "100%" }}>
          <OTSession
            width={"100%"}
            ref={sessionRef}
            eventHandlers={{
              ...sessionEventHandlers,
              sessionDisconnected: (event) => {
                console.log("session disconnected", event);
              },
            }}
            onConnect={() => {
              canStartCall && sessionRef.current.sessionHelper.session.publish(publisher.current);
            }}
            apiKey={selectedCall.api_key}
            sessionId={selectedCall.session_id}
            token={selectedCall.token}
            onSignalRecieve={(data) => {
              console.log("data recieve", data);
            }}
            key={selectedCall.session_id + selectedCall.token}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                height: fullHeight,
                width: "100%",
              }}
            >
              {filteredSubscribers.map((subscriber) => {
                console.log("subscriber", subscriber);
                const isSpeaker = hasRole(subscriber.stream.connection.data, "speaker");

                const isTwoSubscribers = filteredSubscribers.length === 2;

                const userAudioHandler = () => {
                  setSubscribersToRender((prevState) => {
                    return prevState.map((el) =>
                      el.stream.id === subscriber.stream.id
                        ? {
                            ...el,
                            isAudioOn: !el.isAudioOn,
                          }
                        : el
                    );
                  });

                  sessionRef.current.sessionHelper.session.signal({
                    data: subscriber.stream.id,
                    type: subscriber.isAudioOn ? "blockMicro" : "unblockMicro",
                  });
                };

                const userVideoHandler = () => {
                  setSubscribersToRender((prevState) => {
                    return prevState.map((el) =>
                      el.stream.id === subscriber.stream.id
                        ? {
                            ...el,
                            isCameraOn: !el.isCameraOn,
                          }
                        : el
                    );
                  });

                  sessionRef.current.sessionHelper.session.signal({
                    data: subscriber.stream.id,
                    type: subscriber.isCameraOn ? "blockCamera" : "unblockCamera",
                  });

                  // if (subscriber.isCameraOn && !subscriber.isAmsaanPro && isGroupCall) {
                  //   sessionRef.current.sessionHelper.session.signal({
                  //     data: subscriber.stream.id,
                  //     type: "removeSelectedUser",
                  //   });
                  // }

                  setIsOffSpeaker(subscriber.isCameraOn && isGroupCall && subscriber.isAmsaanPro);
                };

                const isSmallWindow = subscriber.isAmsaanPro && isOffSpeaker;
                const isLargeWindow = !subscriber.isAmsaanPro && isOffSpeaker;

                const windowSize = !isTwoSubscribers
                  ? "100%"
                  : isSmallWindow
                  ? "25%"
                  : isLargeWindow
                  ? "75%"
                  : "50%";

                if (isSpeaker) {
                  return (
                    <div
                      style={{
                        position: "absolute",
                        width: 120,
                        height: 120,
                        top: 44,
                        right: 54,
                        zIndex: 100,
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <OTSubscriber
                        eventHandlers={{
                          disconnected: (event) => {
                            console.log("disconnected", event);
                            // setSubscribersToRender((prev) =>
                            //   prev.filter((el) => el.stream.id !== event.target.stream.id)
                            // );
                            // sessionRef.current.sessionHelper.session.unsubscribe(
                            //   event.target.stream.connection
                            // );
                          },
                          connected: (event) => {
                            console.log("connected", event);
                          },
                        }}
                        key={subscriber.stream.id}
                        stream={subscriber.stream}
                        session={sessionRef.current.sessionHelper.session}
                        properties={{
                          width: 120,
                          height: 120,
                          fitMode: "cover",
                          // subscribeToAudio: subscriber.isAudioOn,
                          subscribeToAudio: true,
                          style: { buttonDisplayMode: "off" },
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          bottom: 4,
                          left: "50%",
                          transform: "translate(-50%,0)",
                          zIndex: 100,
                          display: "flex",
                          gap: 4,
                        }}
                      >
                        <button
                          onClick={userAudioHandler}
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                            padding: 0,
                          }}
                        >
                          <img
                            src={subscriber.isAudioOn ? AudioOffIcon : AudioOnIcon}
                            alt="Audio"
                          />
                        </button>

                        <button
                          onClick={userVideoHandler}
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                            padding: 0,
                          }}
                        >
                          <img src={subscriber.isCameraOn ? CamOnIcon : CamOffIcon} alt="Audio" />
                        </button>
                      </div>
                    </div>
                  );
                }

                return (
                  <div
                    style={{
                      width: "100%",
                      height: fullHeight,
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#000",
                    }}
                    key={subscriber.stream.id}
                  >
                    <button
                      disabled={isCountdownStart}
                      onClick={() => {
                        setIsCountdownStart(true);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 4,
                        position: "absolute",
                        top: 16,
                        left: 16,
                        borderRadius: 12,
                        border: "1px solid",
                        borderColor: "orange",
                        color: "orange",
                        backgroundColor: "#fff",
                        padding: 8,
                        zIndex: 200,
                        cursor: isCountdownStart ? "auto" : "pointer",
                      }}
                    >
                      {isCountdownStart ? <Warning /> : <AvTimer />}
                      {isCountdownStart ? (
                        <CountdownTimer
                          countdownTime={countdownTime}
                          options={{ minutes: true, seconds: true }}
                        />
                      ) : null}
                    </button>
                    <div style={{ position: "absolute", top: 16, zIndex: 200 }}>
                      <CallTitleInfo sessionData={selectedCall} />
                    </div>
                    {subscriber.isCameraOn ? (
                      <>
                        <OTSubscriber
                          // onSubscribe={() => {
                          //   //canStartCall &&
                          //   // sessionRef?.current?.sessionHelper.session.subscribe(subscriber);
                          //   try {
                          //     if (canStartCall) {
                          //       sessionRef.current.sessionHelper.session.subscribe(subscriber);
                          //     }
                          //   } catch (error) {
                          //     console.error("Error subscribing:", error);
                          //   }
                          // }}
                          eventHandlers={{
                            disconnected: (event) => {
                              console.log("disconnected", event);
                              // setSubscribersToRender((prev) =>
                              //   prev.filter((el) => el.stream.id !== event.target.streamId)
                              // );
                              // sessionRef.current.sessionHelper.session.unsubscribe(
                              //   event.target.stream.connection
                              // );
                            },
                            connected: (event) => {
                              console.log("connected", event);
                            },
                          }}
                          key={subscriber.stream.id}
                          stream={subscriber.stream}
                          session={sessionRef.current.sessionHelper.session}
                          style={{
                            //maxWidth: "100%",
                            width: "100%",
                            height: "100%",
                            // height: fullHeight,
                          }}
                          properties={{
                            width: "100%",
                            fitMode: "contain",
                            height: fullHeight,
                            subscribeToAudio: subscriber.isAudioOn,
                            style: { buttonDisplayMode: "off" },
                          }}
                        />
                      </>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          maxWidth: 600,
                          height: fullHeight,
                          backgroundColor: "#000",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={NoUserIcon}
                          alt="no user"
                          style={{
                            width: "20%",
                            height: "20%",
                            minHeight: 75,
                            minWidth: 75,
                          }}
                        />
                      </div>
                    )}

                    <div
                      style={{
                        position: "absolute",
                        bottom: 15,
                        left: 0,
                        zIndex: 100,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        onClick={userAudioHandler}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                          margin: "0 10px",
                        }}
                      >
                        <img src={subscriber.isAudioOn ? AudioOffIcon : AudioOnIcon} alt="Audio" />
                      </button>

                      <button
                        onClick={userVideoHandler}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                          margin: "0 10px",
                        }}
                      >
                        <img src={subscriber.isCameraOn ? CamOnIcon : CamOffIcon} alt="Audio" />
                      </button>

                      <button
                        onClick={handleDeclineCall}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                          margin: "0 10px",
                        }}
                      >
                        <img src={DeclineBtn} alt="Decline" />
                      </button>
                    </div>
                  </div>
                );
              })}

              <AsideTools
                newMessagesCount={newMessagesCount}
                setChatVisible={handleToggleChatVisible}
                setSwitchOperatorVisible={openSwitchModal}
              />
              <CallChat
                sessionId={selectedCall.session_id}
                isVisible={isOpen}
                messages={messages}
                setIsVisible={handleToggleChatVisible}
              />
              <SwitchOperator isOpen={isOpenSwitchModal} setIsOpen={closeSwitchModal} />
            </div>
          </OTSession>
          {streamAvailable ? null : (
            <Box
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                top: "calc(-100vh + 40px)",
              }}
            >
              <Lottie
                options={{
                  animationData: Loader,
                  loop: true,
                  autoplay: true,
                }}
              />

              {callState.selectedCall.call.reason === "callback" && (
                <div
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    position: "absolute",
                    bottom: 12,
                    left: "50%",
                    transform: "translate(-50%,0)",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  >
                  {isCountdownStart ? (
                    <CountdownTimer
                      countdownTime={countdownTime}
                      options={{ minutes: true, seconds: true }}
                    />
                  ) : null}

                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={handleDeclineCall}
                  >
                    <img src={DeclineBtn} alt="Decline" />
                  </button>
                </div>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Logo />
      )}
    </Box>
  );
};
