import { URI } from "../constants/defaultConstants";
import { AuthService } from "./AuthService";
import { RequestService } from "./RequestService";

export const CallService = {
  async getCalls(onComplete) {
    try {
      const response = await RequestService({
        headers: {
          "X-Interpreter-Token": AuthService.getUser().token,
          "X-Interpreter-Region": "ua",
        },
      }).getRequest(URI.getCalls);
      // console.log(`${URI.getCalls} ====>>`,response);
      return response;
    } catch (error) {
      console.error("getCalls: ", error);
    }
  },
  async makeCall(client_id) {
    try {
      const response = await RequestService({
        data: { client_id },
      }).postRequest(URI.makeCall);

      return response;
    } catch (error) {
      console.error("makeCall: ", error);
    }
  },
  async answerCall(sessionId) {
    // sessionId =
    // 	'1_MX40NzE4Njk3NH5-MTYyOTM1NjA0MDU5MX5mUm96RTBmckE1ZzMwNGZNbW5walJFWUd-UH4';
    try {
      const response = await RequestService({
        data: { session_id: sessionId },
        // headers: {
        // 	'X-Interpreter-Token': AuthService.getUser().auth_token,
        // },
      }).postRequest(URI.answerCall);
      // console.log('response', response)
      return response;
    } catch (error) {
      console.error("ANSWER CALL ERR : ", error);
      throw error;
    }
  },
  async answerGroupWebProCall(session_id) {
    // sessionId =
    // 	'1_MX40NzE4Njk3NH5-MTYyOTM1NjA0MDU5MX5mUm96RTBmckE1ZzMwNGZNbW5walJFWUd-UH4';
    try {
      const response = await RequestService({
        data: { session_id },
        // headers: {
        // 	'X-Interpreter-Token': AuthService.getUser().auth_token,
        // },
      }).postRequest(URI.answerGroupWebProCall);
      // console.log('response', response)
      return response;
    } catch (error) {
      console.error("ANSWER CALL ERR : ", error);
      throw error;
    }
  },
  async endGroupWebProCall(session_id) {
     try {
      const response = await RequestService({
        data: { session_id },
        // headers: {
        // 	'X-Interpreter-Token': AuthService.getUser().auth_token,
        // },
      }).postRequest(URI.endGroupWebProCall);
      // console.log('response', response)
      return response;
    } catch (error) {
      console.error("ANSWER CALL ERR : ", error);
      throw error;
    }
  },
  async getCallStats() { 
    try {
    const response = await RequestService({
      headers: {
        "X-Interpreter-Token": AuthService.getUser().token,
        "X-Interpreter-Region": "ua",
      },
    }).getRequest(URI.stats);

    return response;
    } catch (error) {
      console.error("getCallStats : ", error);
      
    }
  },
  
  async getContacts(page) {
    try {
      const response = await RequestService({}).getRequest(`${URI.getContacts}?page=${page}`);
      console.log("Your Contacts is", response);
      return response;
    } catch (e) {
      console.log(e);
    }
  },
  async findContacts(value, page) {
    try {
      const response = await RequestService({}).instance.get(
        `${URI.findContacts}/${value}/search?page=${page}`
      );
      console.log("find Contacts is", typeof response, response);

      return response;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getCallHistory(page) {
    try {
      const response = await RequestService({}).getRequest(`${URI.callHist}?page=${page}`);

      return response;
    } catch (e) {
      console.log(e);
    }
  },
  async getContactData(value) {
    try {
      const response = await RequestService({}).getRequest(`${URI.getContactData}/${value}`);
      return response;
    } catch (e) {
      console.log(e);
    }
  },
  async getIncomingCalls(value) {
    try {
      const response = await RequestService({}).getRequest(URI.getIncomingCalls);
      console.log("🚀 ~ file: CallService.js ~ line 73 ~ getIncomingCalls ~ response", response);
      return response;
    } catch (e) {
      console.log(e);
    }
  },
  async saveChatHistory(data) {
    try {
      const request = await RequestService({}).instance.patch(
        `/v2/call/${data.sessionId}/history`,
        data.chat
      );
      console.log("answ", request);
    } catch (e) {
      console.log(e);
    }
  },
  async blockUser(session_id) {
    try {
      const response = await RequestService({ data: { session_id } }).postRequest(URI.blockClient);

      return response;
    } catch (error) {
      console.error("blockUser: ", error);
    }
  },
  async sendChatMessageToClient({ session_id, message }) {
    try {
      const response = await RequestService({ data: { session_id, message } }).postRequest(
        URI.chatClient
      );

      return response;
    } catch (error) {
      console.error("sendChatMessageToClient: ", error);
    }
  },
  async getReasons(lang = "uk") {
    try {
      const response = await RequestService({}).getRequest(`${URI.getReasons}?lang=${lang}`);

      return response;
    } catch (error) {
      console.error("getReasons: ", error);
    }
  },
  async putReasonBySessionId({ sessionId, data }) {
    try {
      const response = await RequestService({
        data,
      }).putRequest(`${URI.putReasonBySessionId}/${sessionId}`);
      console.log("response put ", response);
      return response;
    } catch (error) {
      console.error("getReasons: ", error);
    }
  },
};
