import React, { useState, useEffect, useRef, SetStateAction, Dispatch } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LoadingWithProgress from "./LoadingWithProgress";
import toast from "react-hot-toast";

interface Props {
  onSubmit: (form: FormData, setProgressBar: SetStateAction<Dispatch<number>>, file: Blob) => void;
  videoFormatForSubmit: "mp4" | "webm";
  close?: () => void;
}

const useStyles = makeStyles({
  modalWindow: {
    display: "flex",
    position: "absolute",
    top: "50%",
    left: "50%",
    gap: 20,
    width: "900px",
    height: "600px",
    padding: 16,
    transform: "translate(-50%,-50%)",
    backgroundColor: "white",
    borderRadius: 8,
  },
  videoWindow: { display: "flex", flexDirection: "column", alignItems: "center", gap: 8 },
  buttons: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "92px",
    // justifyContent: "flex-end",
    alignItems: "start",
    gap: "16px",

    "&>button": {
      whiteSpace: "nowrap",
      width: "100%",
    },
  },
  button: {
    width: "140px",
    textTransform: "uppercase",
  },
});

const MAX_FILE_SIZE_MB = 45;
const BYTES_PER_MB = 1024 * 1024;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * BYTES_PER_MB;
const AVERAGE_BITRATE = 1; // in Mbps

const VideoRecorderComponent = ({ onSubmit, close }: Props) => {
  const [recordedVideo, setRecordedVideo] = useState<Blob | null>(null);
  const [recording, setRecording] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [progressBar, setProgressBar] = useState(0);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const recorderRef = useRef<MediaRecorder | null>(null);
  const recordedChunksRef = useRef<Blob[]>([]);
  const initialRemainingMB = (MAX_FILE_SIZE_BYTES * 8) / (AVERAGE_BITRATE * BYTES_PER_MB);
  // @ts-ignore
  const [remainingTime, setRemainingTime] = useState(initialRemainingMB);

  let recordedSize = 0;

  const { t } = useTranslation();
  const styles = useStyles();

  // let mimeType = "video/webm";
   let mimeType = "video/mp4";

  if (!window.MediaRecorder.isTypeSupported(mimeType)) {
    mimeType = "video/mp4";
  }

  const initMountVideo = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: { max: 600 } },
        audio: false,
      });
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      // setVideoRecordOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      initMountVideo();
    }

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    if (progressBar === 100) {
      toast.success("Відео завантажено, чекайте відправки на сервер");
      if (close) {
        setTimeout(() => {
          close();
        }, 300);
      }
    }
  }, [progressBar]);

  const startRecording = () => {
    if (!streamRef.current) return;

    setRecording(true);

    try {
      const recorder = new window.MediaRecorder(streamRef.current, { mimeType });

      recorder.ondataavailable = (event) => {
        if (event.data.size + recordedSize <= MAX_FILE_SIZE_BYTES) {
          recordedSize += event.data.size;
          recordedChunksRef.current.push(event.data);

          const remainingMB = (MAX_FILE_SIZE_BYTES - recordedSize) / BYTES_PER_MB;

          setRemainingTime(remainingMB);
        } else {
          stopRecording();
        }
      };

      recorder.onstop = () => {
        const blob = new Blob(recordedChunksRef.current, { type: mimeType });
        setRecordedVideo(blob);
        setRecording(false);
      };

      recorder.start(100);
      recorderRef.current = recorder;
    } catch (error) {
      console.error(error);
      setRecording(false);
    }
  };

  const stopRecording = () => {
    if (!recorderRef.current) return;

    recorderRef.current.stop();
    recordedSize = 0;
  };

  const clearVideoRecord = () => {
    setRecordedVideo(null);

    initMountVideo();
  };

  const sendVideoMessage = async () => {
    if (!recordedVideo) return;

    setIsLoading(true);
    const form = new FormData();

    // if (videoFormatForSubmit === "mp4") {
    //   mimeType = "video/mp4";
    // }
    const safariType = "video." + mimeType.split("/")[1];

    form.append("type", "media");
    form.append("media", new File([recordedVideo], safariType, { type: safariType }));

    onSubmit(form, setProgressBar, recordedVideo);
    setIsLoading(false);
  };

  const cancelVideoRecord = () => {
    clearVideoRecord();
  };

  // const downloadVideo = () => {
  //   const blob = new Blob([recordedVideo as any], { type: 'video/mp4' }); // Specify the appropriate MIME type
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = 'test video'; // Specify the desired file name
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   window.URL.revokeObjectURL(url);
  // };

  return (
    <div className={styles.modalWindow}>
      <div style={{ overflow: "hidden" }}>
        {!recordedVideo && (
          <video style={{ width: "100%", height: "100%" }} ref={videoRef} autoPlay />
        )}
        {recordedVideo && (
          <video
            style={{ width: "100%", height: "100%" }}
            src={URL.createObjectURL(recordedVideo)}
            controls
          />
        )}
      </div>
      <div className={styles.buttons}>
        {!recording && !recordedVideo && (
          <Button variant="outlined" onClick={startRecording}>
            {t("translationsTab.start")}
          </Button>
        )}
        {recording && (
          <>
            <Button
              style={{ backgroundColor: "#e74f4f", color: "white" }}
              variant="outlined"
              onClick={stopRecording}
            >
              {t("translationsTab.stop")}
            </Button>
            {/* <div>{remainingTime}</div> */}
          </>
        )}
        {recordedVideo && (
          <Button variant="outlined" disabled={isLoading} onClick={sendVideoMessage}>
            {isLoading ? (
              <>
                <LoadingWithProgress value={progressBar} />
              </>
            ) : (
              t("translationsTab.save")
            )}
          </Button>
        )}
        {/* <button onClick={downloadVideo}>download</button> */}
        {recordedVideo && (
          <Button
            style={{ backgroundColor: "#e74f4f", color: "white" }}
            variant="outlined"
            onClick={cancelVideoRecord}
          >
            {t("translationsTab.cancel")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default VideoRecorderComponent;
