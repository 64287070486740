import React, { useState, useEffect } from "react"
import { Box, Typography } from "@material-ui/core"
import Lottie from "react-lottie"

import AcceptCallIcon from "../../assets/voiceCallButtonIcon.svg"
import AcceptCallLottie from "../../assets/lottie/call-incoming.json"
import AudioCallOn from "../../assets/volume-on.svg"
import AudioCallOff from "../../assets/volume-off.svg"
import { useCallContext } from "../../reducers/Call/CallProvider"
import { Timer } from "../../utils/Timer"
import { fullHeight } from "../../utils/constants"
import { useTranslation } from "react-i18next"
import useSound from "use-sound"

export const CallPreview = ({ setCanStartCall, reserveCall, startedAt, handleAccept }) => {
	const { t } = useTranslation()
	const { isVolumeOn, setIsVolumeOn } = useCallContext()

	return (
		<Box
			style={{
				overflowY: "hidden",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				height: fullHeight,
				alignItems: "center",
				paddingTop: 100,
				paddingBottom: 100
			}}
		>
			<Typography variant="h4" style={{ color: "#000" }}>
				{t("call.abonentPay")}
			</Typography>
			<Box alignItems="center">
				<Typography align="center" variant="subtitle1" style={{ color: "#757575" }}>
					{t("call.waiting")}
				</Typography>
				<Typography align="center" variant="h3">
					<Timer start={startedAt} />
				</Typography>
			</Box>
			<Box
				style={{
					justifyContent: "center",
					display: "flex",
					flexDirection: "column"
				}}
			>
				<Box>
					<Box
						style={{
							width: 180,
							position: "relative"
						}}
					>
						<Lottie
							options={{
								animationData: AcceptCallLottie,
								loop: true,
								autoplay: true
							}}
						/>
						<img
							onClick={handleAccept}
							src={AcceptCallIcon}
							style={{
								width: 120,
								height: 120,
								borderRadius: "50%",
								alignSelf: "center",
								boxShadow:
									"0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)",
								backgroundColor: "#4CAF50",
								position: "absolute",
								left: "17%",
								bottom: "19%",
								cursor: "pointer"
							}}
						/>
					</Box>
					<Typography align="center" style={{ color: "#757575", marginTop: -20 }}>
						{t("call.connect")}
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}
