import { IconButton } from "@material-ui/core";
import React from "react";
import { Icon, IconName } from "src/components/Icon";
import styled from "styled-components";

interface Props {
  setChatVisible: () => void;
  setSwitchOperatorVisible: () => void;
  newMessagesCount: number;
}

export default function AsideTools({ setChatVisible, newMessagesCount,setSwitchOperatorVisible }: Props) {
  return (
    <Wrapper>
      <IconButton onClick={setChatVisible}>
        <Icon name={IconName.CHAT} color="#008DFF" size="1.75rem" />
        {newMessagesCount > 0 && <Badge>{newMessagesCount}</Badge>}
      </IconButton>
      <IconButton onClick={setSwitchOperatorVisible}>
        <Icon name={IconName.REDIRECT} color="#008DFF" size="1.75rem" />
      </IconButton>
    </Wrapper>
  );
}

const Wrapper = styled.div({
  width: "3rem",
  display: "flex",
  marginLeft: "auto",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
});

const Badge = styled.span`
  display: flex;
  color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-color: red;
  position: absolute;
  bottom: 8px;
  right: 8px;
  align-items: center;
  justify-content: center;
  font-size: 10px;
`;
